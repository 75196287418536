import * as React from 'react';
import { Form, Row, Col, Input, Modal, Select, Switch, notification } from 'antd';
import credits, {
  CommissionFromType,
  NomenclatureCreditType,
  NomenclatureCreditTypes,
  NomenclaturesCredits,
} from 'lib/api/credits';
import { getSelectOptions } from 'utils';

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface Props {
  initialValue?: NomenclatureCreditTypes;
  code: string;
  onSaved: () => void;
}

const CreditTypesListForm: React.FC<Props> = ({ onSaved, code, initialValue }) => {
  const [parentCredit, setParentCredit] = React.useState<NomenclaturesCredits>();

  const fetchCreditById = React.useCallback(async () => {
    try {
      setParentCredit(await credits.getCreditById(Number(code)));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [code]);

  React.useEffect(() => {
    fetchCreditById();
  }, [fetchCreditById]);

  const handleSubmit = async (values: NomenclatureCreditTypes): Promise<void> => {
    try {
      if (initialValue) {
        await credits.patchCreditTypes(Number(code), Number(initialValue?.id), values);
      } else {
        await credits.postCreditTypes(Number(code), values);
      }

      onSaved();
    } catch (e) {
      const { detail } = e.response.data;

      Modal.error({ title: 'Eroare', content: detail });
    }
  };

  return (
    <Form id="CreditTypesForm" onFinish={handleSubmit}>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24}>
          <Form.Item
            label="Titlu"
            name="title"
            initialValue={initialValue?.title}
            style={formItemStyle}
          >
            <Input placeholder={'Introduceți titlu de credit '} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Client Loial"
            name="is_loial"
            initialValue={initialValue?.is_loial}
            style={formItemStyle}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Remitențe rapide ≥50%"
            name="has_remittances"
            initialValue={initialValue?.has_remittances}
            style={formItemStyle}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Tip gaj"
            name="type"
            initialValue={initialValue?.type}
            style={formItemStyle}
          >
            <Select allowClear placeholder="Tipul de gaj" style={{ width: '100%' }}>
              <Select.Option value={NomenclatureCreditType.WITH_PLEDGE}>Cu gaj</Select.Option>
              <Select.Option value={NomenclatureCreditType.WITHOUT_PLEDGE}>Fără gaj</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="rate"
            label="Rata dobânzi"
            initialValue={initialValue?.rate}
            rules={[
              {
                required: true,
                message: 'Rata dobânzi este necesară!',
              },
            ]}
            style={formItemStyle}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="min_amount"
            label="Suma minimala a creditului"
            initialValue={initialValue?.min_amount}
            rules={[
              {
                required: true,
                message: 'Suma minimala a creditului este necesară!',
              },
            ]}
            style={formItemStyle}
          >
            <Input
              min={parentCredit?.min_amount}
              placeholder={`Suma minimala:${parentCredit?.min_amount}`}
              type="number"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="max_amount"
            label="Suma maximala a creditului"
            initialValue={initialValue?.max_amount}
            rules={[
              {
                required: true,
                message: 'Suma maximala a creditului este necesară!',
              },
            ]}
            style={formItemStyle}
          >
            <Input
              max={parentCredit?.max_amount}
              placeholder={`Suma maximala:${parentCredit?.max_amount}`}
              type="number"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Valuta"
            name="currency"
            initialValue={initialValue?.currency}
            style={formItemStyle}
          >
            <Select
              allowClear
              placeholder="Valute"
              options={getSelectOptions(parentCredit?.currencies)}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Asigurat ODA"
            name="oda_insurance_rate"
            initialValue={initialValue?.oda_insurance_rate}
            style={formItemStyle}
          >
            <Select
              allowClear
              placeholder="Asigurat ODA"
              options={getSelectOptions(parentCredit?.oda_insurance_rates)}
            />
          </Form.Item>
        </Col>

        {parentCredit?.is_term_depending && (
          <>
            <Col span={24}>
              <Form.Item
                name="min_term"
                label="Termenul minim"
                initialValue={initialValue?.min_term}
                rules={[
                  {
                    required: true,
                    message: 'Termenul minim este necesar!',
                  },
                ]}
                style={formItemStyle}
              >
                <Input
                  min={parentCredit?.min_term}
                  placeholder={`Termenul minim:${parentCredit?.min_term}`}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="max_term"
                label="Termenul maxim"
                initialValue={initialValue?.max_term}
                rules={[
                  {
                    required: true,
                    message: 'Termenul maxim este necesară!',
                  },
                ]}
                style={formItemStyle}
              >
                <Input
                  max={parentCredit?.max_term}
                  placeholder={`Termenul maxim:${parentCredit?.max_term}`}
                  type="number"
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Form.Item
            label="Сomision din"
            name="commission_from"
            initialValue={initialValue?.commission_from}
            style={formItemStyle}
          >
            <Select allowClear placeholder="Comision din" style={{ width: '100%' }}>
              <Select.Option value={CommissionFromType.BALANCE}>Comision din sold</Select.Option>
              <Select.Option value={CommissionFromType.AMOUNT}>Comision din sumă</Select.Option>
              <Select.Option value={CommissionFromType.BALANCE_DIVIDED_BY_12}>
                Comision din sold (împărțit la 12)
              </Select.Option>
              <Select.Option value={CommissionFromType.AMOUNT_DIVIDED_BY_12}>
                Comision din sumă (împărțit la 12)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="commission_examination"
            label="Comision de examinare"
            initialValue={initialValue?.commission_examination}
            style={formItemStyle}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="commission_release"
            label="Comision de eliberare"
            initialValue={initialValue?.commission_release}
            style={formItemStyle}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="commission_monthly"
            label="Comision lunar"
            initialValue={initialValue?.commission_monthly}
            style={formItemStyle}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditTypesListForm;
