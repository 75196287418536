import api from '.';
import { stringifyUrl } from 'utils/query';
import { NonFieldsError, FieldsValidationError } from './utils';
import { EnvApi, Properties } from 'types';
import { Locale } from './pages';

export enum SelectTypeOptions {
  API = 'api',
  CUSTOM = 'custom',
}

export enum DisplayTypeMode {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum TextType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
}

export enum NumberType {
  NUMBER = 'number',
  CODE = 'code',
}
export enum SelectType {
  SELECT = 'select',
  CHIPS = 'chips',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export enum ApiNomenclaturesType {
  SUBSIDIARIES = 'subsidiaries',
  CURRENCIES = 'currencies',
  PLACE = 'place',
  COUNTRY = 'country',
  MARRIED = 'married',
  LOAN = 'load',
  DURATION = 'duration',
}

export enum IsActiveForm {
  INACTIVE = 'Inactiv',
  ACTIVE = 'Activ',
}

export enum TypeForm {
  REGULAR = 'regular',
  STEPS = 'steps',
}

export enum FormsFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  TEL = 'tel',
  EMAIL = 'email',
  DATE = 'date',
}

export enum FormType {
  BASIC = 'basic',
  INDIVIDUALS = 'individuals',
  BUSINESS = 'business',
}

export interface ContentFieldProps {
  placeholder?: string;
  label?: string;
  type?: string;
}

export interface FormsFieldProps {
  type?: FormsFieldType;
  value?: string;
  category?: string;
  id?: string;
  pattern?: string;
  i18n: { [key in Locale]?: ContentFieldProps };
  placeholder?: string;
  title?: string;
  label?: string;
  name: string;
  min?: number;
  max?: number;
  options?: Properties;
  option?: Properties;
  order?: number;
  required?: boolean;
  dependencies?: Properties;
}

export interface ContentFormsProps {
  title?: string;
  description?: string;
}
export interface FormsProps {
  id?: number;
  fields: FormsFieldProps[];
  i18n: { [key in Locale]?: ContentFormsProps };
  title?: string;
  description?: string;
  timestamp?: string;
  edited_timestamp?: string;
  slug?: string;
  data?: Properties;
  code?: string;
  type?: TypeForm;
  emails?: string[];
  is_active?: boolean;
  strict?: boolean;
  user?: number;
  send_to?: string | null;
  confirm_phone?: boolean;
}

export interface CorebankingClientSerializer {
  idnp: string;
  phone?: number;
  email?: string;
}

export interface FormsSubmitsProps {
  fields: FormsFieldProps[];
  form_id: number;
  client?: CorebankingClientSerializer;
  client_id?: number;
  emails?: string[];
  timestamp?: string;
  edited_timestamp?: string;
  data?: Properties;
}

export default {
  getForms: async (params: any = {}) => {
    const query = new URLSearchParams({ limit: '10', ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(
      stringifyUrl(`forms?${query.toString()}`, query, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  getFormById: async (id: string | number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `forms/${id}`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  upsertForm: async (form: FormsProps) => {
    let error, data;

    try {
      const { id, ...values } = form;
      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`forms/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl(`forms`, {}, { api: EnvApi.API_MAIN_URL }),
          form,
        ));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, form);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  removeForm: async (id: number) => {
    await api.request.delete(stringifyUrl(`forms/${id}`, {}, { api: EnvApi.API_MAIN_URL }));
  },

  downloadForms: async (id: number, params: any = {}) => {
    return await api.request.get(
      stringifyUrl(`forms/${id}/bulk-export`, { ...params }, { api: EnvApi.API_MAIN_URL }),
      {
        responseType: 'arraybuffer',
      },
      {
        headers: { 'Content-Type': 'blob' },
      },
    );
  },

  getFormsSubmits: async (params: any = {}) => {
    const query = new URLSearchParams({ limit: '10', ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(
      stringifyUrl(`forms-submits?${query.toString()}`, query, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  getFormSubmits: async (params: any = {}) => {
    const query = new URLSearchParams({ limit: '10', ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(
      stringifyUrl(`forms-submits?${query.toString()}`, query, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  getFormSubmitsById: async (id: string | number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `forms-submits/${id}/`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },
  removeFormSubmit: async (id: number) => {
    await api.request.delete(stringifyUrl(`forms-submits/${id}`, {}, { api: EnvApi.API_MAIN_URL }));
  },
  downloadForm: async (id: number) => {
    return await api.request.get(
      stringifyUrl(`forms-submits/${id}/export`, {}, { api: EnvApi.API_MAIN_URL }),
      {
        responseType: 'arraybuffer',
      },
    );
  },
};
